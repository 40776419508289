/* Navbar Styles */
.mainNav{
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar {
  position: relative;
  /* top: 0; */
  background-color:  #002060;
  color: #fff;
  padding-bottom: 8px;
  z-index: 100;
}

.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.5rem;
}

.navbar-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-menu.open {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-item {
  margin-right: 30px;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 5px;
  font-size: 16px;
  transition: 0.4s ease-in-out;
}
.nav-links{
  /* color: white; */
  text-decoration: none;
  /* padding: 5px; */
  /* font-size: 18px; */
  transition: 0.4s ease-in-out;
  
}
.nav-links:hover{
  color: #002060
}

.nav-link:hover{
  color: #006AAC
}
.dropdown-toggle {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.serviceToggle:hover{
  color:#006AAC
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #006AAC;
  border-radius: 8px;
  width: 160px;
  padding: 10px;
}

.dropdown-menu.open {
  display: block;
}
.navbar-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.navbar-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin-bottom: 5px;
}
.appoinmentBtn{
  border: 2px solid #006AAC;
  background-color: #006AAC;
  padding: 10px 20px;
  border-radius: 10px;
  /* font-size: 22px; */
  transition: 0.4s ease-in-out;
}
.appoinmentBtn:hover{
  border: 2px solid white;
  color: white;
}
.appoinmentMobileBtn{
  border: 2px solid #006AAC;
  background-color: #006AAC;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  transition: 0.4s ease-in-out;
  width: 200px;
}

@media screen and (max-width : 1600px) {
  .nav-link {
    font-size: 18px;
    
  }
  .dropdown-toggle {
    font-size: 18px;
  }
}