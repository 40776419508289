.servicesBg {
    position: relative;
    padding-top: 40px;
    color: white;
  }
  
  .servicesBg::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0; /* Align the background image to the right */
    width: 100%; /* Set the width of the background image */
    height: 52vh;
    background-image: linear-gradient(to left, rgba(0, 32, 96, 0.7), rgba(0, 32, 96, 0));
    background-image: url(../../assets/USP/bgpyramid-01.png), linear-gradient(to left, rgba(0, 32, 96, 0.7), rgba(0, 32, 96, 0));
    background-repeat: no-repeat;
    background-size: cover; /* Adjust the background-size property as needed */
    z-index: -1;
  }
  /* For desktop and laptop screens (screen width >= 992px) */


/* For smaller screens, set the height to 0 so it's not visible */
@media (max-width: 991px) {
  .servicesBg::before {
    height: 0;
  }
}
