.flip-card {
    /* width: 400px; */
    height: 400px;
    perspective: 1000px;
    cursor: pointer;
    /* border-radius: 10px; */
    transition: transform 0.6s;
  }
  
  .flip-card-inner {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #002060;
    color: white;
  }
  
  .flip-card-back {
    background-color: #e9e9e9;
    color: black;
    transform: rotateY(180deg);
  }
  