.swiper, swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}
.swiper-pagination {
    position: relative !important;
    margin-top: 10px;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
}
.testimonialContainer{
    background-color: #d6d6d6;
    /* box-shadow: 2px 2px 2px 2px #002060; */
    border: 2px solid #002060;
    padding: 15px;
    border-radius: 10px;
    /* height: 500px; */
    /* width: 1500px; */
}
.clientImage{
    border-radius: 50%;
    width: 100px !important;
}

/* .clamped-text {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
   */

@media screen and(max-width: 768px) {
    .swiper-wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        /* transition-property: transform; */
    }
    
}